<template>
  <div class="expand-row">
    <Row style="margin-bottom: 12px">
      <Col span="6">
        <span>Host: {{row.host}}</span>
      </Col>
      <Col span="6">
        <span>任务描述: {{row.description}}</span>
      </Col>
    </Row>
    <Row v-for="v in row.monitorDetail" :key="v._index">
      <Col span="6">
        <span class="expand-key">源地区: {{ v.region }}</span>
      </Col>
      <Col span="6">
        <span class="expand-key">源省份: {{ v.province }}</span>
      </Col>
      <Col span="6">
        <span class="expand-key">源运营商: {{ v.isp }}</span>
      </Col>
<!--      <Col span="6">-->
<!--        <span class="expand-key">样本数: {{ v.sampleSize }}</span>-->
<!--      </Col>-->
      <Col span="6">
        <span class="expand-key">监测设备: {{ v.moniHost }}</span>
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "httpDetect",
  props: {
    row: Object
  },
}
</script>

<style scoped>

</style>